import { Branding } from "../Branding/Branding";
import styles from "../../App.module.scss";
import AppStore from "../../assets/image/App Store.svg";
import Google from "../../assets/image/Google Play.svg";
import phone from "../../assets/image/phone1.png";
import Elements from "../../assets/image/elements.svg";
import CircleBlue from "../../assets/image/сircle-blue.svg";
import CircleRed from "../../assets/image/сircle-red.svg";
import CircleGreen from "../../assets/image/сircle-green.svg";
import CircleGreenSmall from "../../assets/image/сircle-green-small.svg";
import { useEffect } from "react";
import logo from "../../assets/logo.png";

import firebaseConfig from "../../config/firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";
import { ClickNames, Views } from "../../types/enum/Events";
import { Store } from "../../types/enum/OperatingSystem";

export const HomePage = () => {
  const isMobile = window.innerWidth < 600;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralId = params.get("referralId");
  logEvent(getAnalytics(initializeApp(firebaseConfig)), Views.WebSiteView);
  useEffect(() => {
    if (referralId != null) {
      logEvent(getAnalytics(), `website_view_${referralId}`);
    }
  }, [referralId]);

  const handleButtonClick = (store: string) => {
    if (store === Store.AppStore) {
      window.open(
        "https://apps.apple.com/ng/app/whoriddle/id1638671136",
        "_blank",
      );
      logEvent(getAnalytics(), ClickNames.Ios);
    } else if (store === Store.GooglePlay) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.whoriddle.v2",
        "_blank",
      );
      logEvent(getAnalytics(), ClickNames.Android);
    }
  };

  return (
    <>
      {isMobile ? (
        <div className={styles.logoContainer}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" className={styles.logo} />
          </div>
          <h2 className={styles.logoHeader}>WhoRiddle</h2>
        </div>
      ) : (
        <Branding />
      )}
      <main className={styles.main}>
        <div
          className={styles.mainContent}
          style={isMobile ? { paddingTop: "8vw" } : { paddingTop: "5.625vw" }}
        >
          <h1>WhoRiddle</h1>
          <div className={styles.mainTextContainer}>
            <p className={styles.mainText}>
              About Us {'\n\n'}<br/><br/>

              What started as a parenting hack for a busy physician and research institute founder is now a fully
              developed app that brings people of all ages together solving riddles. <br/><br/>

              Our founder father wrote original “Who Am I” riddles for his young children to enjoy when they were bored,
              tired, hungry or feeling anxious. Their engagement while solving riddles together surprised
              him. <br/><br/>

              Sharing that enchantment with riddle lovers is the why behind WhoRiddle. <br/><br/>

              Our team is composed of a cultural influencer/actor, tv producer/filmmaker, theatre director, published
              journalist, UI/UX designer – and app developer and venture studio founder.<br/><br/>

              ~Team WhoRiddle
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={() => handleButtonClick(Store.AppStore)}>
              <img
                src={AppStore}
                className={styles.storeButton}
                alt="App Store"
              />
            </button>
            <button onClick={() => handleButtonClick(Store.GooglePlay)}>
              <img
                src={Google}
                className={styles.storeButton}
                alt="Google Play"
              />
            </button>
          </div>
        </div>
        <div>
          <img src={phone} className={styles.mainImage} />
        </div>
        <img src={Elements} className={styles.elements} alt="Elemt" />
        <img src={CircleBlue} className={styles.CircleBlue} alt={"circle"} />
        <img src={CircleRed} className={styles.CircleRed} alt={"circle"} />
        <img src={CircleRed} className={styles.CircleRedTwo} alt={"circle"} />
        <img src={CircleGreen} className={styles.CircleGreen} alt={"circle"} />
        <img
          src={CircleGreenSmall}
          className={styles.CircleGreenSmall}
          alt={"circle"}
        />
        {isMobile && (
          <div style={{ paddingBottom: "8vw" }}>
            <Branding />
          </div>
        )}
      </main>
    </>
  );
};
